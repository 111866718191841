import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const CampaignResult = ({ location }) => {
  const title =
    "Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="実例から導き出すTwitterキャンペーン成功の秘訣 | 成果の出るキャンペーン企画とは"
          pagedesc="自社アカウントの実例をもとに、Twitterキャンペーン開催によるフォロワー数・リツイート等結果を分析します。効果的なTwitter運用につながる、販促キャンペーン立案のポイントを解説。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result.jpg"
          pagepath="/blog/2021-campaign-result"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は6分で読むことができます
            </div>
            <h1>
              Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年1月22日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ノートパソコンの画面に表示されているダッシュボード画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result.jpg"
                className="w-full"
              />
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    PARKLoT公式アカウント　キャンペーン結果
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        ・フォロワー数の変化
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        ・1日あたりのフォロワー増加数とリツート数の変化
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    キャンペーン結果から見えてきたこと
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    自社のアカウントでも試してみたい方に
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
              <p>
                SNSマーケティングに欠かせない、Twitterインスタントウィンキャンペーン。毎日、さまざまなキャンペーンが開催されています。
              </p>
              <p>
                盛り上がっているキャンペーンをみて「自社の公式アカウントでもキャンペーンを開催しよう！」と思い立ったものの
                <strong>「どんなキャンペーンが伸びるんだろう？」</strong>
                と頭を抱えたことは、ありませんか？
              </p>
              <p>
                盛り上がっているキャンペーンについて、時間をかけて調べても
                <br />
                <strong>「共通点が分からない」</strong>
                <br />
                <strong>
                  「リツイート数は分かるけど、フォロワー数が増えたのか分からない」
                </strong>
                <br />
                など、盛り上がるキャンペーンの傾向を分析するのは、とても難しいことです。
              </p>
              <p>
                「それなら、仮説を元にキャンペーンをやってみて、結果を見ながら改善していこう！」と、PDCAサイクルを回しながら、いろんなキャンペーンを試してみたいところですが、手動で何回もキャンペーンを開催するのは
                <strong>非常に時間も手間</strong>
                もかかりますし、キャンペーンツールの
                <strong>費用も大きく膨らんでしまいます。</strong>
              </p>
              <p>
                そんな方のお悩みや疑問を解決するため、PARKLoT公式アカウントで開催したキャンペーンの結果を、大公開したいと思います！
              </p>
              <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>
                  Twitterインスタントウィンキャンペーンを開催したいが、企画について悩んでいる。
                </li>
                <li>
                  他社の公式アカウントのキャンペーン結果を知って、自社の企画に活かしたい。
                </li>
              </ul>
              <p>
                本記事では、PARKLoT公式アカウントで開催したキャンペーンについて、以下の情報を公開しています。
              </p>
              <ul className="blog__border-list">
                <p>【調査期間】</p>
                <li>2020年10月1日〜2021年7月22日</li>
                <p>【調査項目】</p>
                <li>キャンペーン開催期間、開催日数</li>
                <li>景品</li>
                <li>フォロワー数</li>
                <li>リツイート数</li>
                <li>1日あたりのフォロワー増加数</li>
                <li>1日あたりのリツイート数</li>
              </ul>
            </div>
            <div>
              <h2 id="id1">PARKLoT公式アカウント キャンペーン結果</h2>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="キャペーン結果"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-1.png"
                maxwidth={640}
              />
              <small>※No.1〜No.5の間はフォロワー数未計測</small>
              <small>
                ※No.3はキャンペーンツイートを誤操作で削除したため、リツイート数不明
              </small>
              <p>
                一覧表を見ていただくと分かる通り、数値が取れていない箇所もありますが、No.6以降に開催したキャンペーンは全て数値を取得しています。
                <br />
                No.10以降も、定期的にキャンペーンを開催しており、それらのキャンペーンの結果も、次回以降の記事で公開いたします。
              </p>
              <p>それでは、キャンペーン結果を見ていきましょう。</p>
            </div>
            <div>
              <p>
                10回のキャンペーンで
                <br />
                <strong>・フォロワー：7,327フォロワー</strong>
                <br />
                <strong>・総リツート数：15,595リツイート</strong>
                <br />
                となりました。
              </p>
              <p>
                キャンペーン終了日のフォロワー数と、キャンペーンツイートのリツイート数の変化を集計したグラフがこちらです。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="累計フォロワー数 累計リツイート数"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-2.png"
                maxwidth={640}
              />
              <h3 id="id2">フォロワー数の変化</h3>
              <p>
                フォロワー数は、キャンペーンを重ねるごとに、順調に増えていましたが、No.10で少し減っています。
              </p>
              <p>
                これは、No.9とNo.10の間に、
                <strong>約半年の期間が空いていることが原因</strong>
                ではないか？と推測しています。
              </p>
              <p>
                一般的にTwitterアカウントのフォロワーを増やすためには、定期的なツイートをすることが必要と言われています。そのため、約半年という長期間、キャンペーンツイートも通常のツイートもしていないことにより、フォロワーが減ったといえそうです。
              </p>
              <p>
                ただし、約半年という長い間、キャンペーンの開催を行っておらず、さらに、キャンペーン以外のツイートもしていなかったにも関わらず、
                <strong>5％の減少に抑えられています。</strong>
                これについては、予想していたより減少率は低かったと感じています。
              </p>
              <p>
                PARKLoTは消費者向けのサービスではなく、企業向けのサービスです。「懸賞用アカウント」と呼ばれる、懸賞に参加するために作成されたアカウントがフォロワーの多くを締めています。
              </p>
              <p>
                推測にはなりますが、懸賞用アカウントの場合は、通常のアカウントと異なり、フォローアカウントの整理などが行われないのかもしれません。その結果、フォロワーの減少が5％に抑えられたという可能性があるのでは？と考えています。
              </p>
            </div>
            <div>
              <h3 id="id3">1日あたりのフォロワー増加数とリツート数の変化</h3>
              <p>
                PARKLoTで開催したキャンペーンは、開催日数が一定ではないため、1日あたりのフォロワー増加数と、リツイート数を集計しました。
              </p>
              <p>
                ※1日あたりのフォロワー増加数…終了日フォロワー数-開始日フォロワー数÷開催日数
              </p>
              <p>※1日あたりのリツイート数…リツイート数÷開催日数</p>
              <p>こちらが、そのグラフです。</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="1日あたりフォロワー増加数 1日あたりリツイート数の変化1"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-3.png"
                maxwidth={640}
              />
              <p>
                多少の上下はあるもの、大きな傾向としては、
                <strong>
                  キャンペーンを開催するにつれ、1日あたりのフォロワー増加数も1日あたりのリツイート数も増えている
                </strong>
                といえそうです。
              </p>
              <p>
                ただ、No7のキャンペーンは1日あたりのフォロワー数も、リツート数も下がっています。
              </p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="1日あたりフォロワー増加数 1日あたりリツイート数の変化2"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-4.png"
                maxwidth={640}
              />
              <p>
                そこで、No6とNo7のキャンペーンを振り返って、何が原因だったのかを分析してみました。
              </p>
              <p>
                No6とNo7のキャンペーンは、日付のみ変更したバナーで開催しています。
              </p>
              <p>・No6のバナー</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.6のキャンペーンのバナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-5.png"
                maxwidth={640}
              />
              <p>・No7のバナー</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.7のキャンペーンのバナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-6.png"
                maxwidth={640}
              />
              <p>
                Twitterのタイムラインは流し読みされる傾向にあるので、
                <strong>
                  パッと見た瞬間に「応募済みのキャンペーンだ」と勘違いされてしまった可能性があるのでは？と考えています。
                </strong>
                また、No.10も大きく数値が下がっています。
              </p>
              <p>
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="1日あたりフォロワー増加数 1日あたりリツイート数の変化3"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-7.png"
                  maxwidth={640}
                />
              </p>
              <p>こちらについては</p>
              <p>
                1. No.9とNo.10で約半年期間が空いた
                <br />
                2. キャンペーンバナーのクリエイティブ
                <br />
                3. 景品が総額5,000円と過去キャンペーンの半額
              </p>
              <p>の3つに原因があるのではないか？と考えています。</p>
              <p>・No.9のバナー</p>
              <img
                layout="constrained"
                placeholder="tracedSVG"
                alt="No.9のキャンペーンのバナー"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/2021-campaign-result/2021-campaign-result-8.png"
                maxwidth={640}
              />
              <p>
                No.9以外のキャンペーンバナーはデザイナーが作成しているのですが、No.9の時は諸事情によりノンデザイナーの社員が作成しました。
              </p>
              <p>
                フォロワーの減少は5％にも関わらず、
                <strong>フォロワー増加数は30%ダウン</strong>、
                <strong>リツイート数は46％ダウン</strong>という結果でした。
              </p>
              <p>
                （余談ですが、弊社ではこの結果を受けて、これからは、絶対に「キャンペーンバナーはデザイナーが作成する」と決めました！）
              </p>
            </div>
            <div>
              <h2 id="id4">キャンペーン結果から見えてきたこと</h2>
            </div>
            <p>
              最後に、今回のキャンペーン結果から、見えてきたことを、まとめていきたいと思います。
            </p>
            <ul className="blog__border-list">
              <p>
                1.{" "}
                <strong>
                  キャンペーンを続けると、フォロワー数とリツイート数が伸びていく
                </strong>
              </p>
              <p>
                2.{" "}
                <strong>
                  一定期間キャンペーンを開催しないと、フォロワー数やリツイート数が減少する
                </strong>
              </p>
              <p>
                3.{" "}
                <strong>
                  キャンペーンバナーのクリエイティブが、結果に影響する可能性がある
                </strong>
              </p>
              <p>
                4. <strong>景品の総額が、結果に影響する可能性がある</strong>
              </p>
            </ul>
            <p>
              キャンペーンを続けると、フォロワー数とリツイート数が伸びるのは、ある意味で予想通りでした。ただし、キレイな右肩上がりではないので、現在も継続してキャンペーンを開催し、現在進行形でデータを収集しています。
            </p>
            <p>
              キャンペーンバナーと景品の総額については、今回はサンプル数が少なかったため、現時点で明確に影響があるとはいえません。今回の結果を受けて、さまざまなキャンペーンバナー、景品の組み合わせでキャンペーンを現在も開催しています。
            </p>
            <p>
              近日中には、
              <strong>
                引き続き行ったキャンペーンのレポートをこちらのブログお届けする予定
              </strong>
              ですので、ブログの更新をお待ちいただければと思います。
            </p>
            <div>
              <h2 id="id5">自社のアカウントでも試してみたい方に</h2>
              <p>
                今回の結果から見えてきたように、Twitterキャンペーンの結果については、一定の法則はあるものの、さまざまな要因によって変化するといえそうです。
              </p>
              <p>
                そのため、PARKLoTでは
                <strong>
                  「SNSキャンペーンはPDCAサイクルを回して、繰り返し実施することが重要」
                </strong>
                だと考えています。
              </p>
              <p>
                一般的な
                <strong>
                  SNSキャンペーンツールは価格が高かったり、キャンペーン1回につきいくら
                </strong>
                、といった価格設定が多く、PDCAサイクルを回すことが難しいといった課題があります。
              </p>
              <p>
                PARKLoTはそのような課題を解決するため、
                <strong>月額制で何回でも実施でき</strong>、さらに、
                <strong>1ヶ月55,000円〜というリーズナブルな価格</strong>
                で、サービスを提供しています。
              </p>
              <p>
                この記事を読んで、自社の公式アカウントでも、さまざまなキャンペーンを繰り返し実施したいと思われた方は、お気軽にお問い合わせください！
              </p>
              <p>
                初めてPARKLoTをご利用いただく方には、無料で1時間のキャンペーン企画のご相談を承っております。この記事には書ききれなかった、多数のキャンペーンの実績から、貴社に最適なキャンペーンのご提案をさせていただきます。
              </p>
            </div>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                  className="m-auto"
                />
              </div>
            </Link>
            <a
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
            </a>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="matsuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default CampaignResult
